import React from "react"
import { Container } from "@material-ui/core"
import Layout from "../shared/layout/Layout"
import styled from "@emotion/styled"

const BaseTemplateLayout = ({ children }) => (
  <Layout>
    <PageContainer className={"secondary-segment"}>
      <Content>{children}</Content>
    </PageContainer>
  </Layout>
)

const PageContainer = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
`

const Content = styled(Container)`
  font-size: 21px;
  max-width: 70ch;
  h3 {
    margin-bottom: 0.5rem;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0.4rem;
  }
  h5 {
    margin-top: 0;
    font-family: monospace;
    font-size: 1rem;
  }
`

export default BaseTemplateLayout
