import React from "react"
import BaseTemplateLayout from "./BaseTemplateLayout"
import { graphql } from "gatsby"
import OutlinedButton from "../shared/components/OutlinedButton"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const shortCodes = { OutlinedButton }

export const query = graphql`
  query($slug: String!) {
    post: contentfulProject(slug: { eq: $slug }) {
      title
      companyName
      article: post {
        mdx: childMdx {
          body
        }
      }
    }
  }
`

const PostTemplate = ({ data: { post } }) => {
  return (
    <BaseTemplateLayout>
      <h3>{post.companyName}</h3>
      <h1>{post.title}</h1>
      <MDXProvider components={shortCodes}>
        <MDXRenderer>{post.article.mdx.body}</MDXRenderer>
      </MDXProvider>
    </BaseTemplateLayout>
  )
}

export default PostTemplate
